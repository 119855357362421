<template>
  <!-- 回答中心首页 -->
  <div id="answerCenter" :style="{ height: windowHeight }">
    <div class="navbar">
      <div class="userInfo">
        <img :src="userInfo.avatar" class="userAvatar" alt="" />
        <div class="userName">{{ userInfo.username }}</div>
        <!-- <div class="status">已认证</div> -->
      </div>
      <div class="center-nav">
        <div
          v-for="(nav, index) in leftNav"
          :key="index"
          :class="['nav', nav.checked ? 'active' : '']"
          @click.stop="to(index, nav)"
        >
          {{ nav.label }}
        </div>
      </div>
    </div>
    <div class="container">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "index",
  data() {
    return {
      leftNav: [
        {
          id: "0",
          path: "/answerCenter/list",
          label: "待回答问题",
          checked: false,
        },
        {
          id: "1",
          path: "/answerCenter/myQuestion",
          label: "我的问题",
          checked: false,
        },
        {
          id: "2",
          path: "/answerCenter/history",
          label: "历史记录",
          checked: false,
        },
      ],
    };
  },
  computed: {
    windowHeight() {
      return window.innerHeight - 40 + "px";
    },
    ...mapState(["userInfo"]),
  },
  watch: {
    $route(to) {
      const index = this.leftNav.findIndex((n) => n.path === to.path);
      this.leftNav = this.leftNav.map((n) => {
        return {
          ...n,
          checked: false,
        };
      });
      if (index !== -1) {
        this.leftNav[index].checked = true;
      } else {
        this.leftNav[0].checked = false;
        this.leftNav[0].checked = true;
      }
    },
  },
  methods: {
    to(index, item) {
      this.leftNav.forEach((element, i) => {
        if (i == index) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      });
      this.$router.push(item.path);
    },
  },
  mounted() {
    if (this.$route.path == "/answerCenter") {
      this.$router.push("/answerCenter/list");
    }
    const index = this.leftNav.findIndex((n) => n.path === this.$route.path);
    this.leftNav = this.leftNav.map((n) => {
      return {
        ...n,
        checked: false,
      };
    });
    if (index !== -1) {
      this.leftNav[index].checked = true;
    }
  },
};
</script>
<style lang="scss" scoped>
#answerCenter {
  width: calc(100% - 40px);
  margin: 20px;

  border-radius: 8px;
  background: #f8f8f8;
  display: flex;
  flex-wrap: nowrap;
  .navbar {
    width: 180px;
    height: 100%;
    background: rgba(37, 40, 48, 1);
    border-radius: 8px 0px 0px 8px;
    .userInfo {
      text-align: center;
      margin-top: 64px;
      font-size: 18px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #ffffff;
      line-height: 27px;
      .userAvatar {
        border-radius: 50%;
        margin-bottom: 9px;
      }
      .status {
        font-size: 12px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #0a7aff;
        line-height: 18px;
        text-decoration: underline;
        margin-top: 8px;
      }
    }
    .center-nav {
      margin-top: 36px;
      .nav {
        text-align: center;
        font-size: 14px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: rgba(170, 170, 170, 0.8);
        line-height: 56px;
        cursor: pointer;
        &.active {
          background: rgba(0, 117, 246, 0.1);
          color: #0075f6;
        }
      }
    }
  }
  .container {
    width: calc(100% - 200px);
    height: 100%;
    margin-left: 20px;
    box-shadow: 0px 0px 20px 0px rgba(118, 118, 128, 0.24);
    border-radius: 0px 0px 8px 8px;
    overflow: auto;
  }
}
</style>